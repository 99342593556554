import React, { useState, useEffect, useReducer } from "react";
import "./Terraformer.css";
import { apiRequest, apiRoutes } from "../../services";
import Modal from "../../components/Modal";
import { AccountPicker } from "./AccountPicker";
import { ResourcesPicker } from "./ResourcesPicker";
import { TerraformationPlan } from "./TerraformationPlan";
import {
  faListCheck,
  faRightLeft,
  faQuestionCircle,
  faStarHalfStroke,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TerraformationProgress } from "./TerraformationProgress";
import Copyright from "../../components/Copyright";
import Questions from "../../components/Questions";
import Jwt from "../../utils/jwt";
import { SUPPORTED_RESOURCES } from "./constants";

/** @type import("./index").AsyncAction */
const asyncActionDefault = {
  status: "idle",
  data: [],
  error: null,
};

const TFER_ACTIONS = {
  FETCH_FAQS: "FETCH_FAQS",
  FETCH_FAQS_SUCCESS: "FETCH_FAQS_SUCCESS",
  FETCH_FAQS_ERROR: "FETCH_FAQS_ERROR",

  FETCH_CLIENTS: "FETCH_CLIENTS",
  FETCH_CLIENTS_SUCCESS: "FETCH_CLIENTS_SUCCESS",
  FETCH_CLIENTS_ERROR: "FETCH_CLIENTS_ERROR",

  FETCH_CLIENT_SUBDOMAINS: "FETCH_CLIENT_SUBDOMAINS",
  FETCH_CLIENT_SUBDOMAINS_SUCCESS: "FETCH_CLIENT_SUBDOMAINS_SUCCESS",
  FETCH_CLIENT_SUBDOMAINS_ERROR: "FETCH_CLIENT_SUBDOMAINS_ERROR",

  FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
  FETCH_CUSTOMERS_SUCCESS: "FETCH_CUSTOMERS_SUCCESS",
  FETCH_CUSTOMERS_ERROR: "FETCH_CUSTOMERS_ERROR",

  FETCH_RESOURCES: "FETCH_RESOURCES",
  FETCH_RESOURCES_SUCCESS: "FETCH_RESOURCES_SUCCESS",
  FETCH_RESOURCES_ERROR: "FETCH_RESOURCES_ERROR",

  SET_TERRAFORMATION_PLAN: "SET_TERRAFORMATION_PLAN",
  RESET_TERRAFORMATION_PLAN: "RESET_TERRAFORMATION_PLAN",
  SET_TERRAFORMATION_MODE: "SET_TERRAFORMATION_MODE",
  RESET_STATE: "RESET_STATE",
};

const UI_PROGRESS_STATE = {
  RUN_INITIAL_CHECKS: "RUN_INITIAL_CHECKS",
  ASK_FOR_API_CREDENTIALS: "ASK_FOR_API_CREDENTIALS",
  DISPLAY_ACCOUNT_PICKER: "DISPLAY_ACCOUNT_PICKER",
  DISPLAY_RESOURCES_PICKER: "DISPLAY_RESOURCES_PICKER",
  DISPLAY_TERRAFORMATION_PLAN: "DISPLAY_TERRAFORMATION_PLAN",
  DISPLAY_TERRAFORMATION_PROGRESS: "DISPLAY_TERRAFORMATION_PROGRESS",
  DISPLAY_TERRAFORMATION_RESULTS: "DISPLAY_TERRAFORMATION_RESULTS",
};

const blockingErrorCommonMessage =
  "Error while fetching functional application data. We are working on it. Please try again later.";
const blockingErrorMessagesList = {
  fetching_customers: blockingErrorCommonMessage,
  fetching_resources_data: blockingErrorCommonMessage,
  fetching_customer_api_credentials_status: blockingErrorCommonMessage,
};

/** @returns {import("./index").TFerReducerState} */
function createInitialState() {
  return {
    terraformationMode: null,
    resources: SUPPORTED_RESOURCES.reduce((resources, res) => {
      return {
        ...resources,
        [res]: {
          ...asyncActionDefault,
        },
      }
    }, {}),
    terraformationPlan: SUPPORTED_RESOURCES.reduce((plan, res) => {
      return {
        ...plan,
        [res]: [],
      }
    }, {}),
    clients: {
      ...asyncActionDefault,
    },
    clientsSubdomains: {},
    customers: {
      ...asyncActionDefault,
    },
    questions: {
      ...asyncActionDefault,
    },
    isPremiumTerraformer: Jwt.getItem("all")?.claims?.roles?.some((role) => {
      if (role.role === "Admin") {
        return true;
      }
      const hasTerraformerWrite = role.apps.find(
        (a) => a.app === "TerraformerApp" && a.permissions.includes("WRITE"),
      );
      return role.role === "Premium Csg Member" && hasTerraformerWrite;
    }),
  };
}

/** @param {import("./index").TFerReducerState} state
 * @param {import("./index").TFerAction} action
 * @returns {import("./index").TFerReducerState}
 */
function tferReducer(state, action) {
  switch (action.type) {
    case TFER_ACTIONS.SET_TERRAFORMATION_MODE:
      return {
        ...state,
        terraformationMode: action.data,
      };
    case TFER_ACTIONS.SET_TERRAFORMATION_PLAN:
      return reducerSetTerraformationPlan(
        state,
        action.data.key,
        action.data.update,
      );

    case TFER_ACTIONS.FETCH_RESOURCES:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            ...state.resources[action.resourceType],
            status: "pending",
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "success",
            data: action.data,
          },
        },
      };
    case TFER_ACTIONS.FETCH_RESOURCES_ERROR:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.resourceType]: {
            status: "error",
            error: action.error,
          },
        },
      };

    case TFER_ACTIONS.FETCH_FAQS:
      return {
        ...state,
        questions: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        questions: {
          status: "success",
          data: action.data,
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_FAQS_ERROR:
      return {
        ...state,
        questions: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.FETCH_CUSTOMERS:
      return {
        ...state,
        customers: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: {
          status: "success",
          data: action.data,
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        customers: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "pending",
            data: [],
            error: null,
          },
        },
      };
    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "success",
            data: action.data,
            error: null,
          },
        },
      };
    case TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS:
      return {
        ...state,
        clientsSubdomains: {
          ...state.clientsSubdomains,
          [action.client.salesforce_id]: {
            status: "success",
            data: action.data,
            error: null,
          },
        },
      };

    case TFER_ACTIONS.FETCH_CLIENTS:
      return {
        ...state,
        clients: {
          status: "pending",
          error: null,
          data: [],
        },
      };
    case TFER_ACTIONS.FETCH_CLIENTS_SUCCESS:
      // Some items in the API response come with the same salesforce_id on
      // different regions, we will list them once.
      const memo = {};
      return {
        ...state,
        clients: {
          status: "success",
          data: action.data
            .filter((item) => {
              const isFirstAppearance = memo[item.salesforce_id] == null;
              memo[item.salesforce_id] = true;
              return isFirstAppearance;
            })
            .sort(
              (a, b) =>
                b.customer_company_name.toLowerCase() -
                a.customer_company_name.toLowerCase(),
            ),
          error: null,
        },
      };
    case TFER_ACTIONS.FETCH_CLIENTS_ERROR:
      return {
        ...state,
        clients: {
          status: "error",
          error: action.error,
          data: [],
        },
      };

    case TFER_ACTIONS.RESET_TERRAFORMATION_PLAN:
      const { terraformationPlan } = createInitialState();
      return { ...state, terraformationPlan };

    case TFER_ACTIONS.RESET_STATE:
      return createInitialState();
    default:
      return state;
  }
}

/** @param {import("./index").TFerReducerState} state
 * @param {string} key
 * @param {import("./index").PagerDutyResourceData[]} update
 */
function reducerSetTerraformationPlan(state, key, update) {
  return {
    ...state,
    terraformationPlan: {
      ...state.terraformationPlan,
      [key]: update,
    },
  };
}

export function Terraformer() {
  const [displayUIState, setDisplayUIState] = useState(
    UI_PROGRESS_STATE.RUN_INITIAL_CHECKS,
  );
  const [blockingErrorMessage, setBlockingErrorMessage] = useState(null);
  const [terraformationRunId, setTerraformationRunId] = useState("");

  /** @type {['normal' | 'switch' | null, function(boolean): void]} */
  const [showTerraformationModeButton, setShowTerraformationModeButton] =
    useState(null);

  /** @type [import("./index").TFerReducerState, import("./index").TFerDispatch] */
  const [tferState, dispatch] = useReducer(
    tferReducer,
    null,
    createInitialState,
  );

  const [customerSubdomain, setCustomerSubdomain] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [hasRESTAPIKey, setHasRESTAPIKey] = useState(null);
  const [hasUserAPIKey, setHasUserAPIKey] = useState(null);
  const [apiCredsValidationError, setAPICredsValidationError] = useState("");
  const [isRedirectingToPDIdentity, setIsRedirectingToPDIdentity] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTittle, setModalTittle] = useState("");
  /** @type {[null | 'small', (modalType: null | 'small') => void]} */
  const [modalType, setModalType] = useState(null);
  /** @type {['error' | 'faqs' | 'oauth-redirect', (contentOf: 'error' | 'faqs' | 'oauth-redirect') => void]} */
  const [modalDisplayContentOf, setModalDisplayContentOf] = useState("error");
  /** @type {[null | 'success' | 'error', (result: null | 'success' | 'error') => void]} */
  const [oauthRedirectResult, setOauthRedirectResult] = useState(null);
  const [oauthRedirectMessage, setOauthRedirectMessage] = useState("");

  // Probably we should move this along with the State Reducer to a context
  /** @type [import("./index").TFerAppRemoteConfigMap, function(): void] */
  const [tferAppRemoteConfig, setTFerAppRemoteConfig] = useState(null);

  const getCustomerAPICredentialsStatus = async (customer_subdomain) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppCustomersAPICredentialsStatus}?customer_subdomain=${customer_subdomain}`,
    });
    if (error || !data || !data.status) {
      console.error({
        error_Terraformer_getCustomerAPICredentialsStatus: error,
      });
      return setBlockingErrorMessage(
        blockingErrorMessagesList.fetching_customer_api_credentials_status,
      );
    }

    /** @type {import("./index").CustomerAPICredentialsStatus} */
    const status = data.status;

    if (data.status.has_rest_api_key != null) {
      setHasRESTAPIKey(status.has_rest_api_key);
    }
    if (data.status.has_user_api_key != null) {
      setHasUserAPIKey(status.has_user_api_key);
    }
  };

  const createAPICredentials = async (apiCredential, customerSubdomain) => {
    const [error] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppAPICredentials}?customer_subdomain=${customerSubdomain}`,
      data: {
        rest_api_key: apiCredential,
        customer_subdomain: customerSubdomain,
      },
    });
    if (error && error.status === 400) {
      return setAPICredsValidationError("Invalid API key provided");
    }
    if (error) {
      console.error({
        error_Terraformer_createAPICredentials: error,
      });
      return setBlockingErrorMessage(
        blockingErrorMessagesList.fetching_customer_api_credentials_status,
      );
    }
    setAPICredsValidationError("");
  };

  /** @returns {Promise<[Error, import("./index").TFerAPIResponse]>} */
  const getTFerAppRemoteConfig = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppRemoteConfig}`,
    });
    if (error) {
      return [error, null];
    }
    const remoteConfig = data.remote_config;
    return [null, remoteConfig];
  };

  /** @returns {Promise<[Error, import("./index").Customer[]]>} */
  const getCustomers = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppCustomers}`,
    });
    if (error) {
      return [error, null];
    }
    const customers = data.customers;
    return [null, customers];
  };

  const createCustomer = async (customerCompanyName, customerSubdomain) => {
    const [error] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppCustomers}?customer_subdomain=${customerSubdomain}`,
      data: {
        customer_company_name: customerCompanyName,
        customer_subdomain: customerSubdomain,
      },
    });
    // Ignore 409 errors, it means the customer already exists
    if (error && error.status !== 409) {
      console.error({
        error_Terraformer_createCustomer: error,
      });
      return setBlockingErrorMessage(blockingErrorMessagesList.fetching_customers);
    }
  };

  /** @returns {Promise<[Error, import("./index").Customer[]]>} */
  const getClients = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.getClients,
    });
    if (error) {
      return [error, null];
    }
    const customers = data?.data?.profiles;
    return [null, customers];
  };

  /**
   * @param {string} path
   * @param {string} [key]
   * @param {string[]} [ids]
   * @returns {Promise<[Error, import("./index").PagerDutyResourceData[]]>} */
  const getResourceWithType = async (path, key, ids) => {
    let url = `${apiRoutes.tferApp}/${path}?customer_subdomain=${customerSubdomain}`;
    if (Array.isArray(ids)) {
      url += `&ids=${ids.join(",")}`;
    }
    const [error, resp] = await apiRequest({ method: "get", url });
    if (error || !resp) {
      return [error, null];
    }

    /** @type import("./index").PagerDutyResourceData[] */
    const data = resp[key == null ? path : key];
    return [null, data];
  };

  /** @returns {Promise<[Error, import("./index").FAQ[]]>} */
  const getFAQs = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppFAQs}`,
    });
    if (error || !data) {
      return [error, null];
    }

    return [null, data.faqs];
  };

  /** @type {import("./index").RequestTerraformation} */
  const requestTerraformation = async (everything, plan) => {
    const [error, data] = await apiRequest({
      method: "post",
      url: `${apiRoutes.tferAppTerraformations}?customer_subdomain=${customerSubdomain}`,
      data: {
        everything,
        plan: everything ? null : { resources: plan },
      },
    });
    if (error || data.run_id === "") {
      console.error({ error_Terraformer_requestTerraformation: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    setTerraformationRunId(data.run_id);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS);
  };

  /** @type {import("./index").GetTerraformationStatus} */
  const getTerraformationStatus = async (runId) => {
    const [error, data, response] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}?customer_subdomain=${customerSubdomain}`,
    });

    if (response.status === 404) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "not_found";
    }
    if (response.status === 500) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      setBlockingErrorMessage(blockingErrorCommonMessage);
      return "failed";
    }

    if (error || !data) {
      console.error({ error_Terraformer_getTerraformationStatus: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    return data.status;
  };

  /** @type {import("./index").GetTerraformationLogs} */
  const getTerraformationLogs = async (runId) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}/log?customer_subdomain=${customerSubdomain}`,
    });
    if (error) {
      console.error({ error_Terraformer_getTerraformationLogs: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    return data;
  };

  /** @type {import("./index").DownloadTerraformCode} */
  const downloadTerraformCode = async (runId) => {
    const [error, data, response] = await apiRequest({
      method: "get",
      url: `${apiRoutes.tferAppTerraformations}/${runId}/download?customer_subdomain=${customerSubdomain}`,
    });

    if (error || !response?.headers["content-disposition"]) {
      console.error({ error_Terraformer_downloadTerraformCode: error });
      return setBlockingErrorMessage(blockingErrorCommonMessage);
    }

    const filename = response.headers["content-disposition"]
      .split(";")[1]
      .split("=")[1]
      .replace(/^"|"$/g, "");
    const contentType = response.headers["content-type"];
    const bytes = Uint8Array.from(atob(data), (c) => c.charCodeAt(0));
    var blob = new Blob([bytes], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  /*
   * Declare all dispatcher/selectors here
   */

  /**
   * @param {import("./index").TFerStateResourceType} resourceType
   * @param {string} path
   * @param {string} [key]
   * @param {string[]} [ids]
   * @param {(data: import("./index").PagerDutyResourceData[]) => import("./index").PagerDutyResourceData[]} [dataPatchingFn] - Function to patch the data before dispatching
   * @returns {Promise<void>}
   */
  const dispatchGetResources = async (resourceType, path, key, ids, dataPatchingFn) => {
    dispatch({ type: TFER_ACTIONS.FETCH_RESOURCES, resourceType });

    const [error, data] = await getResourceWithType(path, key, ids);
    if (error) {
      console.error({ error_Terraformer_dispatchGetResources: error, resourceType, path });
      return dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_ERROR,
        resourceType,
        error,
      });
    }

    let patchedData = data;
    if (data && dataPatchingFn) {
      patchedData = dataPatchingFn(data);
    }

    if (data) {
      dispatch({
        type: TFER_ACTIONS.FETCH_RESOURCES_SUCCESS,
        resourceType,
        data: patchedData,
      });
    }
  };

  const dispatchGetFAQs = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_FAQS });
    const [error, res] = await getFAQs();
    if (error) {
      console.error({ error_Terraformer_dispatchGetFAQs: error });
      return dispatch({ type: TFER_ACTIONS.FETCH_FAQS_ERROR, error: error });
    }
    if (res) {
      const faqs = res;
      dispatch({ type: TFER_ACTIONS.FETCH_FAQS_SUCCESS, data: faqs });
    }
  };

  const dispatchGetClients = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_CLIENTS });
    const [error, res] = await getClients();
    if (error) {
      console.error({ error_Terraformer_dispatchGetClients: error });
      setBlockingErrorMessage(blockingErrorMessagesList.fetching_clients);
      return dispatch({
        type: TFER_ACTIONS.FETCH_CLIENTS_ERROR,
        error: error,
      });
    }
    if (res) {
      const clients = res;
      await dispatch({
        type: TFER_ACTIONS.FETCH_CLIENTS_SUCCESS,
        data: clients,
      });
    }
  };

  const getClientSubdomains = async (client) => {
    const [error, data] = await apiRequest({
      method: "get",
      url: `${apiRoutes.getSubdomain}?customer_company_name=${client.customer_company_name}&salesforce_id=${client.salesforce_id}`,
    });
    return [error, data?.subdomains];
  };

  const dispatchGetClientSubdomains = async (client) => {
    const subdomain = tferState.clientsSubdomains[client.salesforce_id];
    if (subdomain == null || subdomain.status === "error") {
      dispatch({ type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS, client });
      const [error, res] = await getClientSubdomains(client);
      if (error) {
        await dispatch({
          type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_ERROR,
          error,
          client,
        });
      }
      await dispatch({
        type: TFER_ACTIONS.FETCH_CLIENT_SUBDOMAINS_SUCCESS,
        data: res,
        client,
      });
    }
  };

  const dispatchGetCustomers = async () => {
    dispatch({ type: TFER_ACTIONS.FETCH_CUSTOMERS });
    const [error, res] = await getCustomers();
    if (error) {
      console.error({ error_Terraformer_dispatchGetUsers: error });
      setBlockingErrorMessage(blockingErrorMessagesList.fetching_customers);
      return dispatch({
        type: TFER_ACTIONS.FETCH_CUSTOMERS_ERROR,
        error: error,
      });
    }
    if (res) {
      const customers = res;
      await dispatch({
        type: TFER_ACTIONS.FETCH_CUSTOMERS_SUCCESS,
        data: customers,
      });
    }
  };

  /** @type {import("./index").TFerDispatchSetPlan} */
  const dispatchSetTerraformationPlan = (key) => (update) => {
    dispatch({
      type: TFER_ACTIONS.SET_TERRAFORMATION_PLAN,
      data: { key, update },
    });
  };

  /** @param {import("./index").TerraformationMode} mode
   * @returns {void}
   */
  const dispatchSetTerraformationMode = (mode) => {
    dispatch({ type: TFER_ACTIONS.SET_TERRAFORMATION_MODE, data: mode });
  };

  /*
   * Declare all helper functions here
   */
  const renderUISubtitleContent = (uiState) => {
    const uiStateContentMap = {
      [UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER]: (
        <>
          <p>
            <strong>[Dutonians Early Access]</strong> Generate the Terraform
            configuration files of your PagerDuty account with a few clicks (
            <i>Only Sandbox accounts allowed during this phase</i>).
          </p>
        </>
      ),
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: (
        <p>Terraformation App is running initial checks...</p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: (
        <p>
          Generate the whole Terraform configuration files of your PagerDuty
          resources by clicking on the button <strong>Terraform
          Everything</strong>, or select the specific ones you plan to manage
          via Terraform from the list below and click on <strong>Terraformation
          Plan</strong>.
        </p>
      ),
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: (
        <p>
          Terraform configuration files written in{" "}
          <code className="tfer-keyword">HCL</code> will be generated for the
          PagerDuty resources shown below. To confirm this action click on{" "}
          <strong>Generate Terraform Code</strong> button. This is a
          read&#x2011;only operation and it cannot produce changes in your
          PagerDuty account until you approve and apply the files using{" "}
          <code className="tfer-keyword">terraform</code>.
        </p>
      ),
    };
    return uiStateContentMap[uiState];
  };

  const renderFeedbackRequestInline = () => {
    const feedbackRequestFormUrl = tferAppRemoteConfig?.["feedback-request-form-url"]?.value;
    if (!feedbackRequestFormUrl) return null;

    return (
      <span style={{ marginLeft: 5, fontSize: 14, color: "#016080" }}>
        (<a style={{ fontSize: 14, color: "#016080", textDecoration: "none" }} title="Got feedback? We're listening" href={feedbackRequestFormUrl} target="_blank">Got feedback?
          <FontAwesomeIcon
            icon={faStarHalfStroke}
            style={{ marginLeft: 3, verticalAlign: "0.01em" }}
          /></a>)
      </span>
    )
  }

  const addNewSubdomain = () => {
    setIsRedirectingToPDIdentity(true);
    const pdIdentityUrl = tferAppRemoteConfig?.["pd-identity-url"]?.value;

    if (!pdIdentityUrl) {
      return;
    }
    window.location.href = pdIdentityUrl;
  }

  const cleanPage = () => {
    setDisplayUIState(UI_PROGRESS_STATE.RUN_INITIAL_CHECKS);
    setCustomerSubdomain("");
    setHasRESTAPIKey(null);
    setHasUserAPIKey(null);
    setBlockingErrorMessage(null);
    setIsModalOpen(false);
    setModalDisplayContentOf("error");
    setModalTittle("");
    setModalType(null);
    setTerraformationRunId("");
    setShowTerraformationModeButton(null);
    dispatch({ type: TFER_ACTIONS.RESET_STATE });
  };

  const openModal = () => {
    setIsModalOpen(true);
  }

  const openModalError = () => {
    setModalDisplayContentOf("error");
    setModalTittle("Error");
    setModalType("small");
    openModal();
  }

  const openModalFAQs = () => {
    setModalDisplayContentOf("faqs");
    setModalTittle("FAQ");
    setModalType(null);
    openModal();
  }

  const openModalOAuthRedirect = () => {
    setModalDisplayContentOf("oauth-redirect");
    setModalTittle("PagerDuty Sandbox account registration result");
    setModalType("small");
    openModal();
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeErrorModal = () => {
    cleanPage()
    closeModal()
  }

  /** @param {import("./index").TerraformationMode} mode */
  const displayTerraformationPlan = (mode) => {
    dispatchSetTerraformationMode(mode);
    setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN);
  };

  /*
   * Declare all useState/useRef handlers here
   */
  useEffect(() => {
    const uiStateProgressCount = {
      [UI_PROGRESS_STATE.RUN_INITIAL_CHECKS]: 0,
      [UI_PROGRESS_STATE.ASK_FOR_API_CREDENTIALS]: 1,
      [UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER]: 2,
      [UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]: 3,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]: 4,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS]: 5,
      [UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS]: 6,
    };

    if (
      showTerraformationModeButton !== null &&
      uiStateProgressCount[displayUIState] <
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton(null);
    }

    if (
      uiStateProgressCount[displayUIState] ===
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER]
    ) {
      setShowTerraformationModeButton("normal");
    }

    if (
      uiStateProgressCount[displayUIState] >=
      uiStateProgressCount[UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN]
    ) {
      setShowTerraformationModeButton("switch");
    }
  }, [displayUIState, tferState.terraformationMode]);

  useEffect(() => {
    const isClientsFetched =
      tferState.clients.status !== "idle" ||
      tferState.clients.status !== "pending";
    if (isClientsFetched && tferState.customers.status === "success") {
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER);
    }
  }, [tferState.clients, tferState.customers]);

  useEffect(() => {
    if (tferState.questions.status === "idle") {
      dispatchGetFAQs();
    }

    if (tferState.customers.status === "idle") {
      dispatchGetCustomers();
    }

    if (tferState.clients.status === "idle" && tferState.isPremiumTerraformer) {
      dispatchGetClients();
    }

    if (customerSubdomain && hasRESTAPIKey) {
      setDisplayUIState(UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER);
      return;
    }
  }, [customerSubdomain, hasRESTAPIKey, hasUserAPIKey]);

  useEffect(() => {
    if (blockingErrorMessage) {
      openModalError()
    }
  }, [blockingErrorMessage]);

  useEffect(() => {
    if (displayUIState === UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER) {
      if (tferState.resources.services.status === "idle") {
        dispatchGetResources("services", "services");
      }
      if (tferState.resources.users.status === "idle") {
        dispatchGetResources("users", "users");
      }
      if (tferState.resources.orchestrations.status === "idle") {
        dispatchGetResources("orchestrations", "orchestrations");
      }
      if (tferState.resources.escalationPolicies.status === "idle") {
        // The escalation policies endpoint was made with dashes instead of
        // underscores, producing unwanted complexity, we should use "_" from
        // now on, but escalation policies will be kept like this to prevent
        // breaking the API contract.
        dispatchGetResources("escalationPolicies", "escalation-policies", "escalation_policies");
      }
      if (tferState.resources.schedules.status === "idle") {
        dispatchGetResources("schedules", "schedules");
      }
      if (tferState.resources.teams.status === "idle") {
        dispatchGetResources("teams", "teams");
      }
      if (tferState.resources.tags.status === "idle") {
        dispatchGetResources("tags", "tags");
      }
      if (tferState.resources.businessServices.status === "idle") {
        dispatchGetResources("addons", "addons");
      }
      if (tferState.resources.businessServices.status === "idle") {
        dispatchGetResources("businessServices", "business_services");
      }
    }
  }, [displayUIState]);

  useEffect(() => {
    if (tferState.resources.services.status === "success") {
      const ids = tferState.resources.services.data.map(item => item.id).filter(id => id)
      const servicesStateMap = tferState.resources.services.data.reduce((result, service) => {
        return {
          ...result,
          [service.id]: service,
        }
      }, {})
      const addSummaryToservices = (data) => {
        return data.map((item, idx) => {
          return {
            ...item,
            summary: servicesStateMap[item.id]?.summary,
            html_url: `https://${customerSubdomain}.pagerduty.com/event-orchestration/service/${ids[idx]}`,
          }
        })
      }

      dispatchGetResources(
        "servicesOrchestrations",
        "orchestrations/services",
        "orchestrations_services",
        ids,
        addSummaryToservices,
      );
      dispatchGetResources("servicesOrchestrationsCacheVariables", "orchestrations/services/cache-variables", "orchestrations_service_cache_variables", ids);
    }
  }, [tferState.resources.services.status])

  useEffect(() => {
    if (tferState.resources.orchestrations.status === "success") {
      const ids = tferState.resources.orchestrations.data.map(item => item.id).filter(id => id)
      const orchestrationsStateMap = tferState.resources.orchestrations.data.reduce((result, orchestration) => {
        return {
          ...result,
          [orchestration.id]: orchestration,
        }
      }, {})
      const addSummaryToOrchestrations = (data) => {
        return data.map(item => {
          return {
            ...item,
            summary: orchestrationsStateMap[item.id]?.summary,
          }
        })
      }

      dispatchGetResources("orchestrationsIntegrations", "orchestrations/integrations", "orchestration_integrations", ids);
      dispatchGetResources(
        "orchestrationsGlobal",
        "orchestrations/global",
        "orchestrations_global",
        ids,
        addSummaryToOrchestrations,
      );
      dispatchGetResources("orchestrationsGlobalCacheVariables", "orchestrations/global/cache-variables", "orchestrations_global_cache_variables", ids);
      dispatchGetResources(
        "orchestrationsRouter",
        "orchestrations/router",
        "orchestrations_router",
        ids,
        addSummaryToOrchestrations,
      );
      dispatchGetResources(
        "orchestrationsUnroutedRouter",
        "orchestrations/unrouted",
        "orchestrations_unrouted",
        ids,
        addSummaryToOrchestrations,
      );
    }
  }, [tferState.resources.orchestrations.status])

  useEffect(() => {
    if (tferState.resources.teams.status === "success") {
      const ids = tferState.resources.teams.data.map(item => item.id).filter(id => id)

      dispatchGetResources(
        "teamMemberships",
        "team-memberships",
        "team_memberships",
        ids,
      );
    }
  }, [tferState.resources.teams.status])

  useEffect(() => {
    if ((tferState.resources.teams.status !== "success") || (tferState.resources.users.status !== "success")) {
      return;
    }
    if (tferState.resources.businessServices.status === "success") {
      const addSummaryToBSS = (data) => {
        return data.map(item => {
          const parts = item.id.split(".");
          const bsId = parts[0];
          const sType = parts[1];
          const bsSummary = tferState.resources.businessServices.data?.find(x => x.id == bsId)?.summary;
          let sSummary = parts[2];
          switch (sType) {
          case "team":
            sSummary = "Team " + tferState.resources.teams.data?.find(x => x.id == parts[2])?.summary;
            break;
          case "user":
            sSummary = "User " + tferState.resources.users.data?.find(x => x.id == parts[2])?.summary;
            break;
          }
          const summary = sSummary + " for "+bsSummary;
          return { ...item, summary, };
        })
      }
      const ids = tferState.resources.businessServices.data.map(item => item.id).filter(id => id)
      dispatchGetResources("businessServicesSubscribers", "business_services_subscribers", "business_services_subscribers", ids, addSummaryToBSS);
    }
  }, [tferState.resources.businessServices.status, tferState.resources.teams.status, tferState.resources.users.status])

  useEffect(() => {
    const fetchAppRemoteConfig = async () => {
      const [error, data] = await getTFerAppRemoteConfig();
      if (error) {
        console.error({ error_Terraformer_fetchAppRemoteConfig: error });
        return
      }
      setTFerAppRemoteConfig(data);
    }
    fetchAppRemoteConfig();
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const subdomainRegistered = queryParams.get('subdomain_registered');
    const error = queryParams.get('error');

    if (subdomainRegistered) {
      setOauthRedirectResult('success');
      setOauthRedirectMessage(subdomainRegistered);
    }

    if (error) {
      let errMessage = ""
      try {
        errMessage = error.replace(/\+/g, ' ');
      } catch (e) {
        errMessage = `Error: ${error}`;
      }
      setOauthRedirectResult('error');
      setOauthRedirectMessage(errMessage);
    }

    if (subdomainRegistered || error) {
      queryParams.delete('subdomain_registered');
      queryParams.delete('error');
      window.history.replaceState({ search: queryParams.toString() }, "", window.location.pathname);
      openModalOAuthRedirect();
    }
  }, []);

  return (
    <>
      <div id="tfer-app__container">
        <div className="tfer-app__main">
          <div id="tfer-app__title">
            {tferState.questions.status === "success" &&
              tferState.questions.data.length > 0 && (
                <div id="tfer-app__faq" onClick={openModalFAQs}>
                  Frequently Asked Questions
                  <FontAwesomeIcon
                    id="PSStatus-FAQIcon"
                    icon={faQuestionCircle}
                  />
                </div>
              )}
            <div className="tfer-app--flex tfer-app--flex-align-right">
              <h2 id="SuccessOnDemand-title">Terraformations Factory</h2>
              <span className="beta-tag">Early Access</span>
              {renderFeedbackRequestInline()}
            </div>
            {customerSubdomain &&
              (
                <h3 className="tfer-app__customer-info">
                  Company: <strong>{customerCompanyName}</strong> / Subdomain: <strong>{customerSubdomain}</strong>
                </h3>
              )}
          </div>

          <div id="tfer-app__sub-title">
            {renderUISubtitleContent(displayUIState)}
          </div>

          <div id="tfer-app__terraformation-mode">
            <div id="tfer-app__terraformation-mode-container">
              {displayUIState ===
              UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER /* read and check if present from tferAppRemoteConfig */ ? (
                <div style={{ position: "relative" }}>
                  <div className="banner banner-info">
                    <div className="banner-content">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="maintenance-banner-icon"
                      />
                      <span className="banner-message">
                        Disclaimer: If you choose to onboard your account, your
                        resources will be visible to other Dutonians. This will
                        be improved in an upcoming release.
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {displayUIState >=
                    UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                    <button
                      className="tfer-app__btn"
                      title="Change customer subdomain"
                      onClick={cleanPage}
                    >
                      <FontAwesomeIcon icon={faRightLeft} />
                    </button>
                  )}
                  {(displayUIState ===
                    UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN ||
                    displayUIState ===
                      UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS ||
                    displayUIState ===
                      UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS) && (
                    <button
                      className="tfer-app__btn"
                      onClick={() => {
                        dispatch({
                          type: TFER_ACTIONS.RESET_TERRAFORMATION_PLAN,
                        });
                        setDisplayUIState(
                          UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER,
                        );
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faListCheck}
                        style={{ marginRight: 10 }}
                      />
                      Back to Resources selection
                    </button>
                  )}
                </div>
              )}

              {displayUIState === UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER && (
                <button
                  className="tfer-app__btn"
                  onClick={addNewSubdomain}
                  disabled={
                    isRedirectingToPDIdentity ||
                    tferAppRemoteConfig?.["pd-identity-url"]?.value == null
                  }
                >
                  {!isRedirectingToPDIdentity ? (
                    <span>Add your account</span>
                  ) : (
                    <span style={{ fontSize: 12 }}>
                      Redirecting to PD OAuth...
                    </span>
                  )}
                </button>
              )}

              {showTerraformationModeButton === "normal" && (
                <div>
                  <button
                    className="tfer-app__btn"
                    onClick={() => {
                      displayTerraformationPlan("everything");
                    }}
                  >
                    Terraformate Everything
                  </button>
                  <button
                    className="tfer-app__btn tfer-app__btn--featured"
                    onClick={() => {
                      displayTerraformationPlan("plan");
                    }}
                  >
                    Terraformate by Plan
                  </button>
                </div>
              )}

              {showTerraformationModeButton === "switch" && (
                <div className="pills-container">
                  <ul className="pills-list">
                    <li
                      className={
                        displayUIState ===
                          UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                        tferState.terraformationMode === "everything"
                          ? "pill-list-item-active"
                          : "pill-list-item"
                      }
                    >
                      Terraformate Everything
                    </li>
                    <li
                      className={
                        displayUIState ===
                          UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN &&
                        tferState.terraformationMode === "plan"
                          ? "pill-list-item-active"
                          : "pill-list-item"
                      }
                    >
                      Terraformate by Plan
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="tfer-app__content-container">
            <div className="tabs-container">
              <div className="tfer-app__tab-content-container">
                {displayUIState === UI_PROGRESS_STATE.RUN_INITIAL_CHECKS && (
                  <></>
                )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_ACCOUNT_PICKER && (
                    <AccountPicker
                      hasRESTAPIKey={hasRESTAPIKey}
                      validationError={apiCredsValidationError}
                      tferState={tferState}
                      onChangeClient={dispatchGetClientSubdomains}
                      onChangeSubdomain={(subdomain) => {
                        if (subdomain) {
                          getCustomerAPICredentialsStatus(subdomain);
                        }
                      }}
                      onSubmit={async (apiCredential, customerSubdomain, customerCompanyName) => {
                        if (!hasRESTAPIKey) {
                          await createCustomer(
                            customerCompanyName,
                            customerSubdomain,
                          );
                          await createAPICredentials(
                            apiCredential,
                            customerSubdomain,
                          );
                          await getCustomerAPICredentialsStatus(
                            customerSubdomain,
                          );
                        }
                        setCustomerSubdomain(customerSubdomain);
                        setCustomerCompanyName(customerCompanyName);
                      }}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.ASK_FOR_API_CREDENTIALS && (
                    <>ASK_FOR_API_CREDENTIALS</>
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_RESOURCES_PICKER && (
                    <ResourcesPicker
                      tferState={tferState}
                      setPlan={dispatchSetTerraformationPlan}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PLAN && (
                    <TerraformationPlan
                      tferState={tferState}
                      requestTerraformation={requestTerraformation}
                      customerSubdomain={customerSubdomain}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_PROGRESS && (
                    <TerraformationProgress
                      terraformationRunId={terraformationRunId}
                      getTerraformationLogs={getTerraformationLogs}
                      getTerraformationStatus={getTerraformationStatus}
                      downloadTerraformCode={downloadTerraformCode}
                      tferAppRemoteConfig={tferAppRemoteConfig}
                    />
                  )}
                {displayUIState ===
                  UI_PROGRESS_STATE.DISPLAY_TERRAFORMATION_RESULTS && (
                    <div>DISPLAY_TERRAFORMATION_RESULTS</div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <Copyright />
        </div>
      </div>
      <>
        <Modal
          show={isModalOpen}
          title={modalTittle}
          handleClose={closeModal}
          modalFitContent
          type={modalType}
        >
          {
            modalDisplayContentOf === "error" && (
              <div style={{ margin: "20px" }}>
                <p>{blockingErrorMessage}</p>
                <div className="tfer-app--center-content">
                  <button
                    className="OnBoarding-container-right-button button-regular"
                    onClick={closeErrorModal}
                  >
                    Close and Restart
                  </button>
                </div>
              </div>
            )
          }

          {
            modalDisplayContentOf === "faqs" && (
              tferState.questions.data.length > 0 && (
                <Questions questionsArray={tferState.questions.data} />
              )
            )
          }

          {
            modalDisplayContentOf === "oauth-redirect" && (
              <div style={{ margin: "20px" }}>
                {
                  oauthRedirectResult === "success" && (
                    <>
                      <p>Subdomain registered: <strong style={{color: "#117170"}}>{oauthRedirectMessage}</strong></p>
                      <p style={{color: "#6f6c6c"}}>You should be able to see your subdomain in the list now.</p>
                    </>
                  )
                }
                {
                  oauthRedirectResult === "error" && (
                    <>
                      <p style={{ color: "#b71c1c"}}>An error ocurred while registering the subdomain.</p>
                      <p><strong>{oauthRedirectMessage}</strong></p>
                    </>
                  )
                }
              </div>
            )
          }
        </Modal>
      </>
    </>
  );
}
