import { useState } from 'react';
import { DialogInfo } from './DialogContent';

const useDialog = () => {
  const [openDialogId, setOpenDialogId] = useState<string | null>(null);

  const openDialog = (dialogId: string) => {
    setOpenDialogId(dialogId);
  };

  const closeDialog = () => {
    setOpenDialogId(null);
  };

  const isDialogOpen = (dialogId: string) => {
    return openDialogId === dialogId;
  };

  return {
    openDialog,
    closeDialog,
    isDialogOpen
  };
};

export default useDialog;