import { useState, useEffect } from "react";
import { useAuth } from "../../../UserContext.js";
import {
  faCaretDown,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showTextSeverity, colorBySeverity, returnIcon } from "../../../utils/colors";
import { apiRoutes, apiRequest } from "../../../services";
import ThankYou from "../../../components/ThankYou";
import pagerUser from "../../../assets/pageyUser.png";

export default function CartAlert({ userRole, setShowModalOpenCart, setOpenFreeTrialModal }) {
  const { deleteAlertsInCart, cartAlert, clearAllAlerts } = useAuth()
  const [premiumRequest, setPremiumRequest] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loadingSubmitCart, setLoadingSubmitCart] = useState(false);
  const [formValues, setFormValues] = useState({
    requestType: 'Account Team Request',
    requestUrgency: "Normal Urgency 7-10 Business Days",
    requestDetails: ''
  });

  useEffect(() => {
    getPremiumRequest()
  }, [])

  const getPremiumRequest = async () => {
    const [error, data] = await apiRequest({
      method: "get",
      url: apiRoutes.premiumRequest,
    });
    if (!error) {
      setPremiumRequest(data?.data?.request_severity)
    }
  }


  const handleInputChange = (e) => {
    const fieldName = e.target.name;

    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: e.target.value,
    }))
  }

  const createAlertObject = (alerts) =>
    alerts.map(alert => ({
      alert_id: alert.id,
      request_alert_title: alert.title,
    }));
  
  const returnArrayMultipleCart = () => {
    const alertsRequestedMultiple = cartAlert?.filter(alert => alert.multiple);
    const arrayMultiple  = alertsRequestedMultiple?.reduce((acc, alert) => {
      let team = acc.find(item => item.team_name === alert.team_name);
      if (!team) {
        team = { team_name: alert.team_name, alerts: [] };
        acc.push(team);
      }
      team.alerts.push({
        alert_id: alert.id,
        request_alert_title: alert.title,
      });
    
      return acc;
    }, []);
    return arrayMultiple
  }

  const buildRequestBody = (cartAlert) => {
    const alertsRequested = cartAlert?.filter(alert => !alert.multiple);
  
    
    return {
      request_type: 'Account Team Request',
      request_urgency: formValues.requestUrgency,
      request_details: formValues.requestDetails,
      alerts_requested: createAlertObject(alertsRequested),
      alerts_requested_multiple: returnArrayMultipleCart(),
    };
  };


  const onSubmitCart = async () => {
    setLoadingSubmitCart(true)
    const body = buildRequestBody(cartAlert);

    const [error, data] = userRole === "Free Trial Viewer" ? 
      await apiRequest({
        method: "post",
        url: apiRoutes.trialContact,
        data: {
          "request_details":body,
          section: "My Alerts"
        }
      }) : await apiRequest({
        method: "post",
        url: apiRoutes.multiservicerequest,
        data: body
      });

    
      if (data?.status === 200 || data?.status_code === 200 ) {
      userRole === "Free Trial Viewer" && setOpenFreeTrialModal(true);
      setSubmitSuccess(true)
      clearAllAlerts()
      setFormValues({
        requestType: 'Account Team Request',
        requestUrgency: "Normal Urgency 7-10 Business Days",
        requestDetails: ''
      });
      
      setTimeout(() => {
        setSubmitSuccess(false)
        setShowModalOpenCart(false)
      }, 1500);
    }
    setShowModalOpenCart(false);
    setLoadingSubmitCart(false);
  }


  const simpleCart = createAlertObject(cartAlert?.filter(alert => !alert.multiple))
  const multipleCart = returnArrayMultipleCart()
  const disabledBtnSave = formValues.requestType !== '' && formValues.requestUrgency !== '' ? '' : 'disabled__dtnSave'

  return (
    <div className="container__detailAlert">
      <section className="container__sectionAlert">
        <div className="modal__containerDetailAlert">
          <div className="header__cartAlerts">
              <div>Health Check Alerts Cart for Service Request Submission</div>
              <div onClick={() => setShowModalOpenCart(false)}><FontAwesomeIcon icon={faX} /></div>
          </div>
          <div className="clearCart__alertsContainer">
            <div>Total Alerts: {cartAlert?.length}</div>
            <div  className="clearCart__alerts" onClick={() => clearAllAlerts()}>
              Clear Cart <FontAwesomeIcon className="icon__clearCartAlert" icon={faTrash} />
            </div>
          </div>
        {submitSuccess && <div className="container__thankYouAlert"><ThankYou /></div>}
          {cartAlert?.length === 0 && !submitSuccess &&
            <div className="cart__isEmpty container__notCartAlert">
              <div className="container__imgUserCartAlert imgUserCart__alerts">
                <img
                  className="img__pagerUserCartAlert"
                  src={pagerUser}
                  alt=''
                />
              </div>
              <div className="not__cart">Your shopping cart is empty</div>
            </div>}
          {!submitSuccess && <div className="alerts__cartContainer"> 
            { cartAlert && cartAlert?.map((newItem, index) => (   
              <div key={index} className="news-item__home news__alerts" style={{display: 'flex', flexDirection: 'column'}}>
                <div className="title__homeAlertCart">
                  <div>{newItem?.title}</div>
                  <div onClick={() =>   deleteAlertsInCart(newItem?.id)}><FontAwesomeIcon className="icon__clearCartAlert" icon={faTrash} /></div>
                </div>
                <div className="container__entity pd__entities">
                  <div className="icon__entity" ><FontAwesomeIcon  icon={returnIcon(newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities)} /></div>
                  <div>{newItem?.pd_entities_labels ? newItem?.pd_entities_labels : newItem?.pd_entities}</div>
                </div>
                <p className="description__newHome">{newItem?.description?.split('The current value is : ')[0].replace('. .','.')}</p>
                <div className="meta__home meta__homeCart" style={{marginTop: 'auto'}}>
                    <span  style={{ color: colorBySeverity(newItem.severity).color}}>{showTextSeverity(newItem.severity)}</span>
                    <span className="date__home">{newItem?.creation_date}</span>
                </div>
              </div>))}
          </div>}
          {cartAlert && cartAlert?.length > 0 && !submitSuccess && <div className="div__border"></div>}
          {cartAlert && cartAlert?.length > 0 && !submitSuccess &&
            <div className="container__requestUrgency">
              <div className="title__modal">Request Urgency</div>
              <div>
                {premiumRequest.length > 0 &&
                  <div className="container__urgencySelect">
                      <select
                      name="requestUrgency"
                      onChange={handleInputChange}
                      className="select__requestUrgency"
                      value={formValues.requestUrgency}
                    >
                      {premiumRequest?.map((item) => (
                        <option key={item?.id} value={item?.description}>{item?.description}</option>
                      ))}
                    </select>
                    <FontAwesomeIcon className="icon__caretDownAlertRequestUrgency" icon={faCaretDown} />
                  </div>}
              </div>
            </div>}
          {cartAlert?.length > 0 && !submitSuccess && userRole !== 'Free Trial Viewer' &&
            <div className="container__requestDetail">
              <div className="title__modal">Request Detail</div>
              <div>
                <textarea name='requestDetails' onChange={handleInputChange} value={formValues.requestDetails} className="textarea__requestDetail">
                </textarea>
              </div>
            </div>}
            {cartAlert?.length > 0 && !submitSuccess &&
              <div className="btn__sendCartAlert">
                  {(simpleCart?.length >= 100 ||
                  multipleCart?.length >= 15) && <div>You have exceeded the cart limit, please remove alerts.</div>}
                <div className="spinner__containerAlert">  
                  {loadingSubmitCart &&
                    <div className="spinner__alerts" style={{height: 'auto', marginLeft:'10px'}}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  }
                </div>
                <button 
                className={`btn__search ${disabledBtnSave}`}
                disabled={
                  formValues.requestUrgency === '' ||
                  simpleCart?.length >= 100 ||
                  multipleCart?.length >= 15
                }
                onClick={() => { onSubmitCart() }}
                >Submit</button>
              </div>
              }
        </div>
      </section>
    </div>
  )
}