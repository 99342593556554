import React, { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  StyledDialogOverlay,
  StyledDialogContent,
  StyledCloseButton,
  StyledDialogHeader,
  StyledDialogTitle,
  StyledDialogDescription
} from './DialogStyled.tsx';

export interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string | ReactNode;
  children?: ReactNode;
}

const Dialog = ({ isOpen, onClose, title, description, children }: DialogProps): JSX.Element | null => {
  if (!isOpen) return null;

  const handleContentClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
  };

  return (
    <StyledDialogOverlay onClick={onClose}>
      <StyledDialogContent onClick={handleContentClick}>
        <StyledCloseButton onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </StyledCloseButton>
        <StyledDialogHeader>
          <StyledDialogTitle>{title}</StyledDialogTitle>
          <StyledDialogDescription>
            {description}
          </StyledDialogDescription>
        </StyledDialogHeader>
        {children}
      </StyledDialogContent>
    </StyledDialogOverlay>
  );
};

export default Dialog;