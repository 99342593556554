import styled from 'styled-components';

export const StyledDialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const StyledDialogContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  position: relative;
`;

export const StyledDialogHeader = styled.div`
  margin-bottom: 16px;
`;

export const StyledDialogTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 0 0 8px 0;
`;

export const StyledDialogDescription = styled.div`
  color: #666;
  line-height: 1.6;
  margin: 0;
  
  p {
    margin-bottom: 16px;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 8px;
    
    &:before {
      content: "•";
      position: absolute;
      left: 0;
      color: #666;
    }
  }
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;

  &:hover {
    color: #333;
  }
`;

export const StyledInfoButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin-left: 8px;
  color: #666;

  &:hover {
    color: #333;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    line-height: 32px;
    font-weight: 700;
    opacity: 0.9;
    margin: 0;
    color: rgb(33, 37, 41);
    font-size: 18px;
  }
`;