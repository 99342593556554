import React, { useState, useEffect } from 'react';
import { DatePicker } from 'rsuite';
import "rsuite/dist/rsuite-no-reset.min.css";
import './ServiceModal.css';
import CustomSelect from '../../CustomSelect/CustomSelect';
import MultiSelectPill from '../../MultiSelectPill/MultiSelectPill';

const ServiceModal = ({ onFormDataChange, initialFormData }) => {
  const [formData, setFormData] = useState(() => ({
    timeline: '',
    requestTypes: [],
    details: '',
    meetingDates: [],
    ...initialFormData
  }));

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    validateForm();
    onFormDataChange(formData);
  }, [formData, onFormDataChange]);

  const validateForm = () => {
    const valid = 
      formData.timeline !== '' &&
      Array.isArray(formData.requestTypes) && formData.requestTypes.length > 0 &&
      formData.details && formData.details.trim() !== '' &&
      Array.isArray(formData.meetingDates) && formData.meetingDates.length > 0 
    setIsValid(valid);
  };

  const handleInputChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    if (date && Array.isArray(formData.meetingDates) && formData.meetingDates.length < 3) {
      const newDates = [...formData.meetingDates, date];
      setFormData(prev => ({ ...prev, meetingDates: newDates }));
    }
  };

  const handleRequestTypesChange = (selected) => {
    setFormData(prev => ({ ...prev, requestTypes: selected }));
  };

  const removeDate = (index) => {
    if (Array.isArray(formData.meetingDates)) {
      const newDates = formData.meetingDates.filter((_, i) => i !== index);
      setFormData(prev => ({ ...prev, meetingDates: newDates }));
    }
  };

  const timelineOptions = [
    'No specific timeline',
    '1-3 Business days',
    '3-5 Business days',
    '5-7 Business days',
    '7-10 Business days',
    '10+ Business days',
  ];

  const consultants = [
    "Alejandro Spuler",
    "Alexis Acevedo",
    "Alexis Muñoz",
    "Amanda Veras",
    "Carmen Retamal",
    "Celeste Vega",
    "Dan Draper",
    "David Rodriguez",
    "Diego Cid",
    "Dutch Rapley",
    "Eddie Willits",
    "Felipe Onituka",
    "Felipe Polhwein",
    "Gavin Reynolds",
    "Gonzalo Robles",
    "Ignacio Garces",
    "Jorge Spuler",
    "José Reyes",
    "Lucas Coelho",
    "Loren Pearce",
    "Manuel Solis",
    "Matheus Garmatz",
    "Pablo Retes",
    "Patricio Cordero",
    "Raphael Souza",
    "Robinson Navas",
    "Rodrigo Morales",
    "Rodrigo Muñoz",
    "Ryan Derobertis",
    "Sebastian Bello",
    "Sebastian Villanelo",
    "Tom Bryant",
    "Victor Orozco",
  ];

  const requestTypes = [
    'Best Practices',
    'Consulting/Advisement',
    'Training/Enablement',
    'Team Optimization Session',
    'Services Optimization',
  ];


  return (
    <section className='premium-support-request__modal-main'>
      <section className='premium-support-request__modal-form'>
        <div className="premium-support-request__modal-group">
          <CustomSelect 
            label={<label>Work Request Timeline <span className='requiredLabel'><em>(Required)</em></span></label>}
            options={timelineOptions} 
            placeholder="Select Timeline"
            style="default"
            onChange={(selected) => handleInputChange('timeline', selected)}
            value={formData.timeline || ''}
          />
        </div>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label className="premium-support-request__modal-label">Work Request Type <span className='requiredLabel'><em>(Required)</em></span></label>
            <MultiSelectPill 
              options={requestTypes} 
              selected={formData.requestTypes || []}
              onChange={handleRequestTypesChange}
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <label className="premium-support-request__modal-label">Work Request Details and Additional Context  <span className='requiredLabel'><em>(Required)</em></span></label>
            <textarea
              className="premium-support-request__modal-textarea"
              value={formData.details || ''}
              onChange={(e) => handleInputChange('details', e.target.value)}
              rows={6}
            />
          </div>
        </section>
        <section className="premium-support-request__modal-col1_dates">
          <div className="premium-support-request__modal-group">
            <label className="premium-support-request__modal-label">Customer Follow Up Meeting Options  <span className='requiredLabel'><em>(Required)</em></span></label>
            <DatePicker 
              placement="autoVerticalStart"
              format="MM/dd/yyyy hh:mm aa"
              onChange={handleDateChange}
              disabledDate={date => date && date.getTime() < Date.now()}
              showMeridian
              style={{ width: '100%' }}
              disabled={Array.isArray(formData.meetingDates) && formData.meetingDates.length >= 3}
              placeholder={Array.isArray(formData.meetingDates) && formData.meetingDates.length >= 3 ? "Max selections reached" : "Select Meeting Date & Time"}
            />
          </div>
          <div className="selected-dates">
            {Array.isArray(formData.meetingDates) && formData.meetingDates.map((date, index) => (
              <div key={index} className="selected-date-chip">
                {date.toLocaleString('en-US', { 
                  month: '2-digit', 
                  day: '2-digit', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit', 
                  hour12: true 
                })}
                <button onClick={() => removeDate(index)} className="remove-date-btn">×</button>
              </div>
            ))}
          </div>
        </section>
        <section className="premium-support-request__modal-col1">
          <div className="premium-support-request__modal-group">
            <CustomSelect 
              label="Consultant" 
              options={consultants} 
              placeholder="Select consultant"
              style="consultant"
              onChange={(selected) => handleInputChange('consultant', selected)}
              value={formData.consultant || ''}
            />
          </div>
        </section>
      </section>
    </section>
  );
};

export default ServiceModal;