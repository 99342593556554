/**
 * @type {Array<import("./index").TFerStateResourceType>}
 * The order of this array will determine the order in which the resources are displayed
 * */
export const SUPPORTED_RESOURCES = [
  "services",
  "users",
  "orchestrations",
  "orchestrationsIntegrations",
  "orchestrationsRouter",
  "orchestrationsUnroutedRouter",
  "orchestrationsGlobal",
  "orchestrationsGlobalCacheVariables",
  "servicesOrchestrations",
  "servicesOrchestrationsCacheVariables",
  "escalationPolicies",
  "schedules",
  "teams",
  "teamMemberships",
  "tags",
  "addons",
  "businessServices",
  "businessServicesSubscribers",
]

/** @type {Record<import("./index").TFerStateResourceType, string>} */
export const RESOURCE_TITLE_TEXT = {
  services: "Technical services",
  servicesOrchestrations: "Services Orchestrations",
  servicesOrchestrationsCacheVariables: "Services Orchestrations Cache Variables",
  users: "Users",
  orchestrations: "Event Orchestrations",
  orchestrationsIntegrations: "Integrations for an Event Orchestration",
  orchestrationsGlobal: "Global Event Orchestrations",
  orchestrationsGlobalCacheVariables: "Global Event Orchestrations Cache Variables",
  orchestrationsRouter: "Event Orchestrations Routers",
  orchestrationsUnroutedRouter: "Event Orchestrations Unrouted Routers",
  orchestrationsServices: "Event Orchestrations Services",
  orchestrationsServicesCacheVariables: "Event Orchestrations Services Cache Variables",
  escalationPolicies: "Escalation Policies",
  schedules: "Schedules",
  teams: "Teams",
  teamMemberships: "Team Memberships",
  tags: "Tags",
  addons: "Add-ons",
  businessServices: "Business Services",
  businessServicesSubscribers: "Business Services Subscribers",
};
