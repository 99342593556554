import React, { ReactNode } from 'react';

export interface DialogInfo {
  id: string;
  title: string;
  description: string | ReactNode;
}

export const dialogContent: Record<string, DialogInfo> = {
  serviceRequests: {
    id: 'serviceRequests',
    title: 'Service Requests Summary',
    description: `Service Request Hours refer to 'On-Demand Hours'. The Service Requests Summary provides an overview of the information related to Service Requests. Click 'View Summary of Requests in Period' for a visualization of requests within the current period.`
  },
  pagerdutyUniversity: {
    id: 'pagerdutyUniversity',
    title: 'PagerDuty University Summary',
    description: `Provides an overview of the information related to the account's PagerDuty University engagements and available services.`
  },
  premiumSupport: {
    id: 'premiumSupport',
    title: 'Premium Support',
    description: 'Shows all open and closed cases with Premium Support.'
  },
  products: {
    id: 'products',
    title: 'My Products',
    description: (
      <div>
        <p>
          Provides product and subscription information: add-ons, licenses, etc. This section displays an overview of how many user licenses/events/credits are Purchased, Deployed and Available. Values shown are numbers of licenses, with some exceptions:
        </p>
        <ul>
          <li>PagerDuty Advance: number of credits</li>
          <li>AIOps: number of events/year</li>
          <li>Live Call Routing: number of lines</li>
          <li>PagerDuty Status Page: number of subscriptions per year</li>
          <li>Workflow Automation: number of Workflows</li>
        </ul>
      </div>
    )
  },
  professionalServices: {
    id: 'professionalServices',
    title: 'Professional Services Summary',
    description: 'The Professional Services Summary displays information related to recent Professional Services projects, including: Project Name, Engagement Manager and Consultant. Enter a search term to display results that match the project name.'
  },
  alerts: {
    id: 'alerts',
    title: 'Latest Alerts',
    description: 'These are recommendations on how the Professional Services Team can help improve the adoption, deployment, maturity and overall well being of the configurations in the PagerDuty environment.'
  },
  solutionGallery: {
    id: 'solutionGallery',
    title: 'Solution Gallery',
    description: 'The Solutions Gallery is a single source of discovery and information on how Premium Services Customers can get help from Professional Services, PagerDuty University, or Customer Success Engineering.'
  }
} as const;